@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Lato);

body {
  font-family: 'Lato';
  background-size: cover;
  background-color: #151515;
}

h1 {
  font-family: 'Lobster';
  font-size: 6rem;
  color: black;
  transform: rotate(-10deg);
}

select {
  background: rgba(255, 255, 255, 0.7);
  outline: none;
  border: 3px solid black;
  color: black;
  font-family: 'Lato';
  font-size: 1.5em;
  padding: 8px;
  margin-right: 0;
  text-align: center;
}

.bg-lightOp {
  background: rgba(255, 255, 255, 0.8);
}

input {
  background: rgba(255, 255, 255, 0.8);
  outline: none;
  border: 3px solid black;
  color: black;
  font-family: 'Lato';
  font-size: 1.5em;
  padding: 8px;
  margin-right: 0;
  text-align: center;
}

button {
  border: 3px solid black;
  font-size: 1.5em;
  padding: 10px;
  margin-left: 0;
  font-family: 'Lobster';
}
